import React, { useState, useEffect } from "react";
import { Grid, Box, TextField, Button, Typography } from '@mui/material';
import useUser from '../App/useUser';
import { useDispatch } from 'react-redux';
import { setSelectedIndex } from "../../features/selectedIndexSlice";

const NAME = "Profile";

export default function Profile() {
    const dispatch = useDispatch();
    const { user } = useUser();
    const [editState, setEditState] = useState(false);
    const [newName, setNewName] = useState(user.name);
    const [newEmail, setNewEmail] = useState(user.email);
    const [newGln, setNewGln] = useState(user.gln);

    useEffect(() => {
        dispatch(setSelectedIndex(NAME));
    }, []);

    const cancelEdit = () => {
        setEditState(false);
        setNewName(user.name);
        setNewEmail(user.email);
        setNewGln(user.gln);
    }

    //TODO
    const saveEdit = () => {

    }

    const buttonStyle = {
        backgroundColor: "#FFF",
        border: "solid 1px #1EB1E0",
        color: "#3284C6",
        fontSize: "12px",
        fontWeight: "400",
        width: "60%",
        '&:hover': {
            backgroundColor: "rgba(30, 177, 224, 0.1)"
        },
        marginTop: 4
    }

    let titleStyle = {
        fontFamily: 'Figtree',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '22px',
        lineHeight: '26px',
        alignItems: 'center',
        color: '#4A4F53',
        width: 'auto'
    }

    let textStyle = {
        fontFamily: 'Figtree',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '16px',
        alignItems: 'center',
        color: '#4A4F53',
        width: 'auto',
        marginTop: 20
    }

    return  <Box name="Profile" height="100%" width="100%">
                <Grid container xs={12} justifyContent="center" alignContent="center" height="100%">
                    <Grid container item direction="column" xs={6} height="90%" rowSpacing={3}>
                        <Grid item container>
                            <Typography variant="h1" component="h1" style={titleStyle}>
                                Profile
                            </Typography>
                        </Grid>
                        <Grid item container rowSpacing={1}>
                            <Grid item container direction="row" columnSpacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant="p" component="p" style={textStyle}>
                                        Name: 
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {editState ?
                                        <TextField value={newName} fullWidth label="Name" variant="outlined" onChange={(e) => setNewName(e.target.value)} type="text"/>
                                    :
                                        <Typography variant="p" component="p" style={textStyle}>
                                            {user.name}
                                        </Typography>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item container direction="row" columnSpacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant="p" component="p" style={textStyle}>
                                        Email:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {editState ?
                                        <TextField value={newEmail} fullWidth label="Email" variant="outlined" onChange={(e) => setNewEmail(e.target.value)} type="text"/>
                                    :
                                        <Typography variant="p" component="p" style={textStyle}>
                                            {user.email}
                                        </Typography>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item container direction="row" columnSpacing={2}>
                                <Grid item xs={6}>
                                    <Typography variant="p" component="p" style={textStyle}>
                                        Gln:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {editState ?
                                        <TextField value={newGln} fullWidth label="Gln" variant="outlined" onChange={(e) => setNewGln(e.target.value)} type="text"/>
                                    :
                                        <Typography variant="p" component="p" style={textStyle}>
                                            {user.gln}
                                        </Typography>                                 
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        {editState ?
                            <Grid item container direction="row" columnSpacing={2}>
                                <Grid item xs={6}>
                                    <Button sx={buttonStyle} onClick={cancelEdit}>Save</Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button sx={buttonStyle} onClick={cancelEdit}>Cancel</Button>
                                </Grid>
                            </Grid>
                        :
                            <Grid item container direction="row" columnSpacing={2}>
                                <Grid item xs={6}>
                                    <Button sx={buttonStyle} disabled onClick={() => setEditState(true)}>Edit</Button>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Box>;
}