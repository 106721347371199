import ToolsApi from './ToolsApi';
import Config from '../config/config';

export default function toolsAdminApi() {
    const { deleteCall } = ToolsApi();

    const deleteAllProducts = async () => {
        let url = Config.API_URL + "api/source-items";
        
        let body = undefined
        let query = undefined
        return deleteCall(url, query, body);
    };

    const deleteAllMappings = async (type, version) => {
        let url = Config.API_URL + "api/mapping/" + type + "/" + version;
        
        let body = undefined
        let query = undefined
        return deleteCall(url, query, body);
    };

    return {
        deleteAllProducts,
        deleteAllMappings,
    };
}