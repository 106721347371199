import React, { useState, useEffect } from "react";
import { Grid, Box, Button, TextField, Typography, InputAdornment } from '@mui/material';
import OnneHelperBody from "./OnneHelperBody.js";
import OnneHelperHeader from "./OnneHelperHeader";

export default function OnneHelper({dataModel, type, version, defaultId}) {
    const [selectedModel, setSelectedModel] = useState(undefined);
    let headerStyle = {
        background: "linear-gradient(0deg, rgba(30, 177, 224, 0.1), rgba(30, 177, 224, 0.1)), #FFFFFF",
    }

    return  <Box sx={{height: 600, width: 600, borderRadius: "4px"}}>
                <Grid container direction="column" height={"100%"}>
                    <Grid container item height={"20%"} style={headerStyle}>
                        <OnneHelperHeader setSelectedModel={setSelectedModel} type={type} version={version} options={dataModel} defaultId={defaultId}/>
                    </Grid>
                    <Grid container item height={"80%"}>
                        <OnneHelperBody model={selectedModel} type={type} version={version} />
                    </Grid>
                </Grid>
            </Box>
}