import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Checkbox } from '@mui/material';

const BpIcon = styled('span')(() => ({
    borderRadius: '3px',
    width: 20,
    height: 20,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  }));
  
const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#fff',
    boxShadow: 'inset 0 0 0 1.5px #3284C6, inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        borderRadius: '3px',
        display: 'block',
        width: 20,
        height: 20,
        background: 'linear-gradient(#3284C6, #3284C6) center / 9px 9px no-repeat',
        content: '""',
    },
});

export default function OnneCheckButton(props) {
    return (
      <Checkbox
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    );
  }