import React from "react";
import { Grid, Box, ListItem, Typography } from '@mui/material';

export default function MappingListItem({field, mapped}) {


    return <ListItem>
                <Grid container item>
                    <Grid container item direction="row">
                        <Typography width="75%">{field}</Typography>
                        {
                            mapped
                            ? (
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "25%",
                                    gap: "10px",
                                    alignItems: "center"
                                }}>
                                    <Box 
                                        component="img"
                                        sx={{
                                            height: 8,
                                            width: 16
                                        }}
                                        src="UploadProduct/mapped.png"
                                    />
                                    <Typography>Mapped</Typography>
                                </Box>
                            )
                            : (
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "25%",
                                    gap: "10px",
                                    alignItems: "center"
                                }}>
                                    <Box 
                                        component="img"
                                        sx={{
                                            height: 14,
                                            width: 14
                                        }}
                                        src="UploadProduct/not_mapped.png"
                                    />
                                    <Typography>Not mapped</Typography>
                                </Box>
                            )
                        }
                    </Grid>
                </Grid>
           </ListItem>
}   