import ToolsApi from './ToolsApi';
import Config from '../config/config';
import useToken from '../components/App/useToken';

export default function useToolsCustomDatamodelUpload() {
    const { post, get, deleteCall } = ToolsApi();
    
    const postCustomDataModel = async (data) => {        
        let url = Config.API_URL + "api/datamodel";
        
        let body = data
        let query = undefined
        return post(url, query, body);
    };

    const deleteDataModel = async (dm, version) => {        
        let url = Config.API_URL + "api/datamodel/" + dm + "/" + version;
        
        let body = undefined
        let query = undefined
        return deleteCall(url, query, body);
    };

    return {
        postCustomDataModel,
        deleteDataModel
    };
}