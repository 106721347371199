import { createSlice } from "@reduxjs/toolkit";

export const joyrideSlice = createSlice({
    name: 'joyride',
    initialState: {
        value:{
            run: false,
            steps: [],
        }
    },
    reducers: {
        setJoyrideState: (state, action) => {
            state.value = action.payload;
        },
    }
});

export const { setJoyrideState } = joyrideSlice.actions;

export default joyrideSlice.reducer;