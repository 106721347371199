import { useDispatch } from 'react-redux';
import { setOpenDialog } from "../features/openDialogSlice";

export default function ToolsDialog() {
    const dispatch = useDispatch();

    const open = (nTitle, nContent, nActions) => {
        dispatch(setOpenDialog({open: true, scroll: 'paper', title: nTitle, content: nContent, actions: nActions}))
    }

    const close = () => {
        dispatch(setOpenDialog({open: false, scroll: undefined, title: undefined, content: undefined, actions: []}));
    }

    return {
        open,
        close,
    };
}