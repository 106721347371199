import ToolsApi from './ToolsApi';
import Config from '../config/config';


export default function toolsMappingApi() {
    const { post, get, deleteCall } = ToolsApi();

    const getDataModel = async (type, version, gpc) => {
        let url = Config.API_URL + "api/datamodel/" + type + "/" + version + "/" + gpc;
        
        let body = undefined
        let query = undefined
        return get(url, query, body);
    };

    const getSourceDataModel = async () => {
        let url = Config.API_URL + "api/source-datamodel";
        
        let body = undefined
        let query = undefined
        return get(url, query, body);
    };

    async function getMapping(type, version, gpc) {
        let url = Config.API_URL + "api/mapping/" + type + "/" + version + "/" + gpc;
        
        let body = undefined
        let query = undefined
        return get(url, query, body);
    }

    async function addMapping(type, version, gpc, body) {
        let url = Config.API_URL + "api/mapping/" + type + "/" + version;
        /* if(gpc !== undefined) {
            url = url + "/" + gpc;
        } */
        
        let query = undefined
        return post(url, query, body);
    }

    async function deleteMapping(type, version, gpc, body) {
        let url = Config.API_URL + "api/mapping/" + type + "/" + version;
        if(gpc !== undefined) {
            url = url + "/" + gpc;
        }
        
        let query = undefined
        return deleteCall(url, query, body);
    }

    async function addGpcsMapping(type, version, fieldId, body) {
        let url = Config.API_URL + "api/mapping-gpcs/" + type + "/" + version + "/" + fieldId;
        
        let query = undefined
        return post(url, query, body);
    }

    async function getGpcsMapping(type, version, fieldId) {
        let url = Config.API_URL + "api/mapping-gpcs/" + type + "/" + version + "/" + fieldId;
        
        let body = undefined
        let query = undefined
        return get(url, query, body);
    }

    async function getDataModelEntry(type, version, fieldId) {
        let url = Config.API_URL + "api/datamodelentry/" + type + "/" + version + "/" + + fieldId;
        
        let body = undefined
        let query = undefined
        return get(url, query, body);
    }

    async function getDataModels(type, version, fieldId) {
        let url = Config.API_URL + "api/datamodels";
        
        let body = undefined
        let query = undefined
        return get(url, query, body);
    }

    async function getSourceItems(query) {
        //let url = limit !== undefined ? Config.API_URL + "api/source-items?limit=" + limit : Config.API_URL + "api/source-items";
        let url = Config.API_URL + "api/source-items";
        
        let body = undefined;
        return get(url, query, body);
    }

    async function getLanguages(type, version) {
        let url = Config.API_URL + "api/datamodel/" + type + "/" + version + "/languages";
        
        let body = undefined
        let query = undefined
        return get(url, query, body);
    }

    

    return {
        getDataModel,
        getMapping,
        addMapping,
        deleteMapping,
        getDataModelEntry,
        getDataModels,
        getSourceItems,
        getLanguages,
        getSourceDataModel,
        getGpcsMapping,
        addGpcsMapping,
    };
}