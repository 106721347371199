import { createSlice } from "@reduxjs/toolkit";

export const refreshDataModelsSlice = createSlice({
    name: 'refreshDataModels',
    initialState: {
        value: false,
    },
    reducers: {
        setRefreshDataModels: (state, action) => {
            state.value = action.payload;
        },
    }
});

export const { setRefreshDataModels } = refreshDataModelsSlice.actions;

export default refreshDataModelsSlice.reducer;