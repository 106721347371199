import { createSlice } from "@reduxjs/toolkit";

export const sourceItemSlice = createSlice({
    name: 'sourceItem',
    initialState: {
        items: [],
    },
    reducers: {
        setSourceItems: (state, action) => {
            state.items = action.payload;
        },
    }
});

export const { setSourceItems } = sourceItemSlice.actions;

export default sourceItemSlice.reducer;