import React from "react";
import { Typography, Grid, Button, Box } from '@mui/material';
import { useNavigate  } from 'react-router-dom';

export default function WorkspaceSection({saveTargetModel}) {
    const navigate = useNavigate();

    const sectionStyle = {
        gap: "20px",
        backgroundColor: "rgba(30, 177, 224, 0.1)",
        padding: "30px 40px 0px 40px",
        margin: "50px 50px 60px 50px"
    }

    const buttonStyle = {
        backgroundColor: "#FFF",
        border: "solid 1px #1EB1E0",
        color: "#3284C6",
        fontSize: "12px",
        fontWeight: "400",
        width: "60%",
        '&:hover': {
            backgroundColor: "rgba(30, 177, 224, 0.1)"
        }
    }

    return  <Grid item container xs={12} direction="column" style={sectionStyle}>
                <Grid item>
                    <Typography variant="addTitles" sx={{fontSize: "22px", lineHeight: "26px"}}>Go to your Workspace</Typography>
                </Grid>
                <Grid item>
                    <Typography color="#666E71" fontSize="16px" fontWeight="400" width="80%">Start managing and mapping your products in a simple and agile way.</Typography>
                </Grid>
                <Grid item>
                    <Button sx={buttonStyle} onClick={saveTargetModel}>Save</Button>
                </Grid>
                <Grid item container xs justifyContent="center">
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column-reverse"
                    }}>
                        <Box 
                            component="img"
                            sx={{
                                height: 216,
                                width: 348
                            }}
                            src="UploadTargetModel/bg_image_workspace.png"
                        />
                    </Box>
                </Grid>
            </Grid>

}   