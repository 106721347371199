import React, { useEffect, useState } from "react";
import { List, Grid, Typography } from '@mui/material';
import MappingListItem from "./MappingListItem";

export default function MappingPreview({processedMappingValues}) {
    return  <Grid container direction="column" paddingTop={2}>
                <Grid item>
                    <List sx={{
                        'li': {
                            backgroundColor: "#FFF"
                        },
                        'li:nth-of-type(odd)': {
                            backgroundColor: "rgba(248, 248, 248, 1)"
                        },
                        overflowY: "scroll",
                        height: "500px"
                    }}>
                        {processedMappingValues.map((item, key) => {
                            return <MappingListItem key={key} field={item.field} mapped={item.mapped} />
                        })}
                    </List>
                </Grid>
            </Grid>
}   