import { createSlice } from "@reduxjs/toolkit";

export const openAlertSlice = createSlice({
    name: 'openAlert',
    initialState: {
        value: {open: false, message: undefined, severity: undefined }
    },
    reducers: {
        setOpenAlert: (state, action) => {
            state.value = action.payload;
        },
    }
});

export const { setOpenAlert } = openAlertSlice.actions;

export default openAlertSlice.reducer;