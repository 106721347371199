import ToolsApi from './ToolsApi';
import Config from '../config/config';
import useToken from '../components/App/useToken';

export default function useToolsProductUpload() {
    const { data, post, put } = ToolsApi();
    const { token, setTokenValid } = useToken();
    
    const postProduct = async (file, gpc, key, classification) => {        
        const formData = new FormData();
        formData.append('sourceDataModelFile', file);
        formData.append('dataModelVersion', '3.1.20');
        formData.append('dataModelType', 'GS1');
        formData.append('dataModelGpc', gpc);
        formData.append('key', key);
        if(classification !== undefined && classification !== '' && classification !== null) {
            formData.append('classification', classification);
        }
 
        const response = await fetch(Config.API_URL + 'api/source-data-model',
            {
                method: 'POST',
                body: formData,
                headers: new Headers({
                    'Authorization': 'Bearer ' + token,
                })
            }
        )
        
        let actualData = await response.json();
        
        if(actualData.code === 2) {
            setTokenValid("false");
        }

        return actualData;
    };

    const getMappingDataProducts = async () => {
        /* let url = Config.API_URL + 'api/source-data-model';
        
        let body = undefined
        return get(url, query, body); */

        return {
            "error": false,
            "payload": {
                "key": "",
                "classification": "",
            }
        }
    };

    const updateProduct = async (id, field, value) => {
        let url = Config.API_URL + 'api/source-item/' + id;

        let body = {};
        body[field] = value;
        let query = undefined;
        return put(url, query, body);
    }

    return {
        postProduct: postProduct,
        getMappingDataProducts,
        updateProduct
    };
}