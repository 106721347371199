import React, { useEffect, useState } from "react";
import UploadProductBanner from "./UploadProductBanner";
import { Grid, Box } from '@mui/material';
import LoadingSection from "./LoadingSection"
import AddProductStaticSection from "./AddProductStaticSection";
import ProductUploadResult from "./ProductUploadResult";
import { useSelector } from 'react-redux';
import readXlsxFile from 'read-excel-file'
import { useDispatch } from 'react-redux';
import { read, utils } from 'xlsx';
import { setSelectedIndex } from "../../features/selectedIndexSlice";

const NAME = "AddProducts";

export default function AddProducts() {
    const dispatch = useDispatch();
    const [isResultAvailable, setIsResultAvailable] = useState(false);
    const isProductUploading = useSelector((state) => state.productUpload.value);
    const [mappedValues, setMappedValues] = useState([]);
    const [fileUploaded, setFileUploaded] = useState(undefined)
    const [fileData, setFileData] = useState(undefined);

    useEffect(() => {
        dispatch(setSelectedIndex(NAME));
    }, []);

    useEffect(() => {
        if(fileUploaded !== undefined) {
            // readXlsxFile(fileUploaded).then((rows) => {
            //     setFileData(rows);
            // })
            const reader = new FileReader()
            let e = {}
            reader.onload = async (e) => { 
                const wb = read(e.target.result);
                const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
                const data = utils.sheet_to_json(ws,{defval: '', header: 1}); // generate objects
                setFileData(data);
                
            };
            reader.readAsArrayBuffer(fileUploaded)
        }
    }, [fileUploaded])

    const firstRowStyle = {
        height: "30%"
    };

    const secondRowStlye = {
        height: "65%",
        backgroundColor: "#FFFFFF"
    }

    return  <Box name="AddProducts" height="calc(100% - 75px)" width="100%">
                <Grid container height="100%" >
                    <Grid item xs={12} style={firstRowStyle}>
                        <UploadProductBanner 
                            setIsResultAvailable={setIsResultAvailable} 
                            setMappedValues={setMappedValues} 
                            setFileUploaded={setFileUploaded} 
                        />
                    </Grid>
                    <Grid item container xs={12} style={secondRowStlye}>
                    {
                        isProductUploading 
                        ? (
                            <LoadingSection/>
                          )
                        : isResultAvailable ?   
                                <ProductUploadResult 
                                    mappingResponse={mappedValues} 
                                    fileData={fileData} 
                                    fileUploaded={fileUploaded} 
                                />
                            : 
                                <AddProductStaticSection/>
                    }
                    </Grid>
                </Grid>
            </Box>;
}
