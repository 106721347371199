import { useDispatch } from 'react-redux';
import { setOpenAlert } from "../features/openAlertSlice";

export default function ToolsNotifications() {
    const dispatch = useDispatch();

    const success = (msg) => {
        let severity = "success";
        let errorMessage = msg;
        dispatch(setOpenAlert({open:true, message: errorMessage, severity: severity}))
    }

    const error = (msg) => {
        let severity = "error";
        let errorMessage = msg;
        dispatch(setOpenAlert({open:true, message: errorMessage, severity: severity}))
    }

    const warning = (msg) => {
        let severity = "warning";
        let errorMessage = msg;
        dispatch(setOpenAlert({open:true, message: errorMessage, severity: severity}))
    }

    const info = (msg) => {
        let severity = "info";
        let errorMessage = msg;
        dispatch(setOpenAlert({open:true, message: errorMessage, severity: severity}))
    }

    const close = () => {
        dispatch(setOpenAlert({open:false, message: undefined, severity: undefined}));
    }

    return {
        success,
        error,
        warning,
        info,
        close,
    };
}