import { useState } from "react";

export default function useTableStates() {
    const getStates = () => {
        let states = {}
        if(localStorage.getItem("tableStates") !== undefined && localStorage.getItem("tableStates") !== '' && localStorage.getItem("tableStates") !== null){
            states = JSON.parse(localStorage.getItem("tableStates"));
        } 
        
        return states;
    };
    const [states, setStates] = useState(getStates());
    const saveStates = (states) => {
        localStorage.setItem("tableStates", JSON.stringify(states));
        setStates(states);
    };
    return {
        setStates: saveStates,
        states,
    };
}
