import React from "react";
import { Grid, Box, Typography } from '@mui/material';
import ProductTitle from "./ProductTitle"

export default function AddProductStaticSection() {

    const imageSection = {
        display: "flex",
        alignItems: "center",
        justifyContent: "end"
    }

    return  <Grid width="100%" height="100%">                
                <Grid item>
                    <ProductTitle/>
                </Grid>
                <Grid item paddingLeft="50px" paddingTop="20px">
                    <Typography fontSize="24px" fontWeight="400" color="#4A4F53">Upload your products from here!</Typography>            
                </Grid>
                <Grid xs item style={imageSection}>
                    <Box 
                        component="img"
                        className="bg-image"
                        sx={{
                            height: 550,
                            width: 852,
                            marginRight: "7%"
                        }}
                        alt="Image of a woman and a desk"
                        src="UploadTargetModel/bg_image_table.png"
                    />
                </Grid>
            </Grid>
}   