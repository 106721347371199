import React, { useState, useEffect } from "react";
import { Box, Typography, Grid  } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { DataGridPro } from '@mui/x-data-grid-pro';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 30,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#CCF3D7',
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#3AAE62',
    },
  }));

export default function MappingStatus() {
    const [total, setTotal] = useState(0);
    const [rows, setRows] = useState([]);

    const renderProgressBar = (value, total) => {
        let percentage = (100*value)/total;
        return  <Grid container style={{width: '100%'}} columnSpacing={1} alignContent={'center'} justifyContent={'center'}>
                    <Grid item xs={10}>
                        <BorderLinearProgress
                            variant="determinate"
                            size="xl"
                            value={percentage}
                        />
                        <Typography 
                            style={{
                                color:'#FFFFFF',
                                position: 'absolute',
                                transform: "translate(50%, -110%)",
                            }}
                        >
                            {value}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography style={{color:'#3888C8'}}>
                            {total}
                        </Typography>
                    </Grid>
                </Grid> 
        
                
    }

    const columns = [
        {
            field: 'classification',
            headerName: 'Classification',
            align: 'left',
            minWidth: 150,
            width: 400,
            renderHeader: () => (
                <Typography variant="selected">Classification ({total})</Typography>
            ),
        },
        {
            field: 'allAttributes',
            headerName: 'All Attributes',
            align: 'left',
            minWidth: 150,
            width: 400,
            renderCell: (params) => renderProgressBar(params.row.filled, params.row.total),
            renderHeader: () => (
                <Typography variant="selected">All Attributes</Typography>
            ),
        },
        {
            field: 'mandatoryAttributes',
            headerName: 'Mandatory Attributes',
            align: 'left',
            minWidth: 150,
            width: 400,
            renderCell: (params) => renderProgressBar(params.row.mandatoryFilled, params.row.total)
            ,
            renderHeader: () => (
                <Typography variant="selected">Mandatory Attributes</Typography>
            ),
        },
    ]

    const defaultRows = [
        {
            id: 0,
            classification: 'POWAIR0011 - Rotary multitool Pneumatic 566L/min',
            mandatoryFilled: 10,
            filled: 25,
            total: 100,
        },
        {
            id: 1,
            classification: 'POWAIR0011 - Rotary multitool Pneumatic 566L/min',
            mandatoryFilled: 12,
            filled: 34,
            total: 67,
        },
    ]

    useEffect(() => {
        setRows(defaultRows)
    }, [])

    return  <Box sx={{ height: 400, width: '100%' }}>
                <DataGridPro
                    loading={false}
                    disableColumnMenu
                    hideFooterPagination
                    hideFooter
                    rows={rows}
                    style={{height: '100%'}}
                    columns={columns}
                    disableSelectionOnClick
                />
            </Box>
}