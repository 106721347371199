import { createSlice } from '@reduxjs/toolkit'


export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    products: undefined,
    messages: undefined,
  },
  reducers: {
    setProducts: (state, products) => {
        state.products = products
    },
    setMessages: (state, messages) => {
        state.messages = messages
    },
  },
})

// Action creators are generated for each case reducer function
export const { setProducts, setMessages } = dashboardSlice.actions

export default dashboardSlice.reducer