import React, { useEffect, useState } from "react";
import { List, Grid, Typography } from '@mui/material';
import MappingListItem from "./MappingListItem";
import MappingOptions from "./MappingOptions";
import MappingPreview from "./MappingPreview";
import { useDispatch } from 'react-redux';
import { setTargetModel } from "../../features/targetModelSlice";

export default function MappingList({customDataModel}) {
    const dispatch = useDispatch();
    const [processedMappingValues, setProcessedMappingValues] = useState([]); 
    const [name, setName] = useState(undefined);
    const [selectedClassification, setSelectedClassification] = useState(undefined);
    const [selectedKey, setSelectedKey] = useState(undefined);

    const sectionStyle = {
        width: "auto",
        height: "auto",
        margin: "0px 20px 20px 20px",
        padding: "10px 20px 10px 20px",
        gap: "10px"
    }

    useEffect(() => {
        if(customDataModel !== undefined && customDataModel !== null){
            const auxArray = customDataModel.map((elem) => {
                return {field: elem, mapped: undefined};
            });
            setProcessedMappingValues(auxArray);
        }
    }, [customDataModel])

    useEffect(() => {
        dispatch(setTargetModel({
            name: name,
            data: customDataModel,
            key: selectedKey,
            classification: selectedClassification,
        }));
    }, [name, customDataModel, selectedClassification, selectedKey])

    return  <Grid>
                <MappingOptions items={processedMappingValues} name={name} setName={setName} setSelectedClassificationValue={setSelectedClassification} setSelectedKeyValue={setSelectedKey}  />
                <MappingPreview processedMappingValues={processedMappingValues}/>
            </Grid>
}   