import React from "react";
import { Typography, Box } from '@mui/material';

export default function ProductWarningLine({message}) {
    return <Box sx={{
                width: "100%",
                display: "flex",
                gap: "10px"          
            }}>
                    <Box 
                        component="img"
                        sx={{
                            height: 16,
                            width: 17
                        }}
                        src="UploadProduct/warning.png"
                    />
                    <Typography color="#666E71" fontSize="13px" fontWeight="400">{message}</Typography>
            </Box>

}   