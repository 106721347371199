import React, { useState, useEffect } from "react";
import { Grid, Box } from '@mui/material';
import WorkspaceItems from "./WorkspaceItems";
import { useDispatch } from 'react-redux';
import { setSelectedIndex } from "../../features/selectedIndexSlice";

const NAME = "Workspace";

export default function Workspace() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setSelectedIndex(NAME));
    }, []);

    let mainStyle = { marginTop: "20px" };
    let prodsStyle = { marginTop: "0px", paddingLeft: "20px", paddingRight: "20px" };

    return  <Grid container direction="column" name="Mapping" height="100%" width="100%">
                <Grid style={prodsStyle} item container xs={12} height="60%" >
                    <WorkspaceItems />
                </Grid>
            </Grid>;
}
