import React, { useState, useEffect } from "react";
import toolsPublishApi from "../../tools/toolsPublishApi";
import { alpha, styled } from '@mui/material/styles';
import { Grid, Box, Typography, Button, TextField } from '@mui/material';
import { DataGridPro, GridToolbar, GridToolbarContainer, GridToolbarExport, GridRowModes, gridClasses  } from '@mui/x-data-grid-pro';
import useToolsNotifications from "../../tools/ToolsNotifications";

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: "#F8F8F8",
    },
    [`& .${gridClasses.row}.odd`]: {
      backgroundColor: "#FFFFFF",
    },
  }));

export default function PublishGlnSelector({setSelectedGlns}) {
    const {error, success} = useToolsNotifications();
    const {getAllGlns, postGln} = toolsPublishApi();
    const [loading, setLoading] = useState(true);
    const [availableGlns, setAvailableGlns] = useState(undefined)
    const [newGln, setNewGln] = useState(undefined);
    const [newName, setNewName] = useState(undefined);
    const [updateList, setUpdateList] = useState(true);

    useEffect(() => {
        if(updateList) {
            getAllGlns().then((data) => {
                if(!data.error) {
                    if(data.payload !== null) {
                        setAvailableGlns(data.payload);
                    }
                }   
            })
            setUpdateList(false);
        }       

    }, [updateList])

    useEffect(() => {
        if(availableGlns !== undefined && availableGlns !== null) {
            setLoading(false);
        }
    }, [availableGlns])

    const showPostNewSuccess = () => {
        let msg = "New GLN added successfully";
        success(msg);
    }

    const showPostNewError = () => {
        let msg = "Filetype Error: Valid filetypes are xlsx, xls and csv.";
        error(msg);
    }

    const showPostNewValidationError = () => {
        let msg = "Both fields need to be filled.";
        error(msg);
    }

    const postNewGln = () => {
        if(newGln !== undefined && newName !== undefined) {
            postGln({code: newGln, name: newName}).then((data) => {
                if(!data.error) {
                    showPostNewSuccess();
                    setUpdateList(true);
                }
            })
        } else {
            showPostNewValidationError();
        }
    }

    const glnHeaders = [
        {
            field: 'code',
            headerName: 'Gln',
            minWidth: 170,
            align: 'left',
        },
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 170,
            align: 'left',
        },
    ]

    const buttonStyle = {
        backgroundColor: "#FFF",
        border: "solid 1px #1EB1E0",
        color: "#3284C6",
        fontSize: "12px",
        fontWeight: "400",
        width: "60%",
        '&:hover': {
            backgroundColor: "rgba(30, 177, 224, 0.1)"
        }
    }

    return  <Box sx={{height: 600, width: 550, borderRadius: "4px"}}>
                <Typography sx={{width: 550}} fontSize="20px" fontWeight="400" color="#4A4F53">Available GLNs</Typography>
                <Box sx={{height: 350, width: 550, borderRadius: "4px"}} style={{marginBottom: '20px'}}>
                    <DataGridPro
                        sx={{height: "100%", width: "100%"}}
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectedGlns(newSelectionModel);
                        }}
                        loading={loading}
                        columns={glnHeaders}
                        rows={availableGlns !== undefined ? availableGlns : []}
                        getRowId={(r) => r.code}
                        pageSize={25}
                        rowsPerPageOptions={[25]}
                        disableMultipleSelection={true}
                        /* checkboxSelection */
                        /* getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        } */
                    />
                </Box>
                
                <Typography fontSize="20px" fontWeight="400" color="#4A4F53">Add new GLN</Typography>
                <Grid container direction="row" xs={12} columnSpacing={1} rowSpacing={1} sx={{marginTop: "5px"}}>
                    <Grid item xs={6}>
                        <TextField 
                            label="Gln"
                            id="name-basic" 
                            variant="outlined" 
                            value={newGln}
                            onChange={(e) => {setNewGln(e.target.value)}}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField 
                            label="Name"
                            id="name-basic" 
                            variant="outlined" 
                            value={newName}
                            onChange={(e) => {setNewName(e.target.value)}}
                            fullWidth
                        />
                    </Grid>
                    <Grid item container xs={6} columnSpacing={1}>
                        <Grid item xs={6}>
                            <Button 
                                sx={buttonStyle} 
                                onClick={postNewGln}
                                fullWidth
                            >
                                Add Gln
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        
                    </Grid>
                </Grid>
            </Box>
}