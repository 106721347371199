import React, { useEffect } from "react";
import { Typography, Grid, Button, Box , Backdrop, CircularProgress} from '@mui/material';
import { useNavigate  } from 'react-router-dom';
import useToolsProductUpload from '../../tools/toolsProductUpload';
import { useSelector } from 'react-redux';
import useToolsNotifications from "../../tools/ToolsNotifications";

export default function WorkspaceSection({postData}) {
    const {error} = useToolsNotifications();
    const { postProduct } = useToolsProductUpload();
    const gpc = useSelector((state) => state.gpc.value);
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    const sectionStyle = {
        gap: "20px",
        backgroundColor: "rgba(30, 177, 224, 0.1)",
        padding: "30px 40px 0px 40px",
        margin: "50px 50px 60px 50px"
    }

    const buttonStyle = {
        backgroundColor: "#FFF",
        border: "solid 1px #1EB1E0",
        color: "#3284C6",
        fontSize: "12px",
        fontWeight: "400",
        width: "60%",
        '&:hover': {
            backgroundColor: "rgba(30, 177, 224, 0.1)"
        }
    }

    /* useEffect(() => {
        console.log(postData)
    }, [postData]) */

    const showServerError = (text) => {
        let msg = "Server Error";
        error(msg);
    }

    const handleClick = () => {
        handleOpen();
        postProduct(postData.file, gpc, postData.key, postData.classification).then((result) => {
            navigate('/product-browser')
        }).catch((error) => {
            showServerError()
            handleClose();
        })
    }

    return  <Grid item container xs={12} direction="column" style={sectionStyle}>
                <Grid item>
                    <Typography color="#4A4F53" fontSize="22px" fontWeight="600">Go to your Workspace</Typography>
                </Grid>
                <Grid item>
                    <Typography color="#666E71" fontSize="16px" fontWeight="400" width="80%">Start managing and mapping your products in a simple and agile way.</Typography>
                </Grid>
                <Grid item>
                    <Button sx={buttonStyle} onClick={handleClick}>Process</Button>
                </Grid>
                <Grid item container xs justifyContent="center">
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column-reverse"
                    }}>
                        <Box 
                            component="img"
                            sx={{
                                height: 216,
                                width: 348
                            }}
                            src="UploadProduct/bg_image_workspace.png"
                        />
                    </Box>
                </Grid>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                    onClick={handleClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Grid>

}   