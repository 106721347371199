import React, { useState } from "react";
import { Grid } from '@mui/material';
import MappingList from "./MappingList";
import MappingOptions from "./MappingOptions";

export default function MappingSection({headers, selectedKey, setSelectedKey, classification, setClassification}) {
    const [processedMappingValues, setProcessedMappingValues] = useState([]); 

    const sectionStyle = {
        width: "auto",
        height: "auto",
        margin: "0px 20px 20px 20px",
        padding: "10px 20px 10px 20px",
        gap: "10px"
    }

    return  <Grid item container xs={12} direction="column" style={sectionStyle}>
                <Grid item xs >
                    <MappingOptions 
                        items={headers} 
                        selectedKey={selectedKey}  
                        setSelectedKey={setSelectedKey} 
                        classification={classification}  
                        setClassification={setClassification}
                    />
                </Grid>
            </Grid>

}   