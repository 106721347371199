import { AbilityBuilder, Ability } from '@casl/ability'

const adminRole = "ROLE_ADMIN";
const userRole = "ROLE_USER";

export default function defineAbilityFor(user) {
  const { can, cannot, build } = new AbilityBuilder(Ability);

  if(user.roles.includes(adminRole)) {
    can('deleteAll', 'Products');
    can('deleteAll', 'Mappings');
  }

  /* switch(parseInt(user.user_type)){

    // ************* ADMIN *************
    case 1 :
      // ******* PRODUCT BROWSER *******
      can('deleteAll', 'Products');
      can('deleteAll', 'Mappings');

      break;

    // ************* USER *************
    case 2 :
      break;
  } */

  return build();
}