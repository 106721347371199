import { createSlice } from "@reduxjs/toolkit";

export const openDialogSlice = createSlice({
    name: 'openDialog',
    initialState: {
        value: {open: false, scroll: undefined, title: undefined, content: undefined, actions: []}
    },
    reducers: {
        setOpenDialog: (state, action) => {
            state.value = action.payload;
        },
    }
});

export const { setOpenDialog } = openDialogSlice.actions;

export default openDialogSlice.reducer;