import React, { useState, useEffect } from "react";
import UploadCustomDataModelBanner from "./UploadCustomDataModelBanner";
import { Grid, Box } from '@mui/material';
import LoadingSection from "./LoadingSection"
import AddProductStaticSection from "./AddProductStaticSection";
import CustomDataModelUploadResult from "./CustomDataModelUploadResult";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setSelectedIndex } from "../../features/selectedIndexSlice";

const NAME = "TargetModel";

export default function AddCustomDataModel() {
    const dispatch = useDispatch();
    const isProductUploading = useSelector((state) => state.productUpload.value);
    const [isResultAvailable, setIsResultAvailable] = useState(false);
    const [customDataModel, setCustomDataModel] = useState([]);


    useEffect(() => {
        dispatch(setSelectedIndex(NAME));
    }, []);

    const firstRowStyle = {
        height: "30%"
    };

    const secondRowStlye = {
        height: "65%",
        backgroundColor: "#FFFFFF"
    }

    return  <Box name="AddProducts" height="calc(100% - 75px)" width="100%">
                <Grid container height="100%" >
                    <Grid item xs={12} style={firstRowStyle}>
                        <UploadCustomDataModelBanner setIsResultAvailable={setIsResultAvailable} setCustomDataModel={setCustomDataModel}/>
                    </Grid>
                    <Grid item container xs={12} style={secondRowStlye}>
                    {
                        isProductUploading 
                        ? (
                            <LoadingSection/>
                          )
                        : isResultAvailable
                            ? <CustomDataModelUploadResult customDataModel={customDataModel}/>
                            : <AddProductStaticSection/>
                    }
                    </Grid>
                </Grid>
            </Box>;
}
