import * as React from 'react';
import { Button } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { DataGridPro, useGridApiRef, GridColumnMenuContainer,  GridToolbar,GridCsvExportMenuItem, GridToolbarExportContainer  , GridToolbarContainer, GridToolbarExport, GridRowModes, gridClasses, useGridApiContext  } from '@mui/x-data-grid-pro';
import GetAppIcon from '@mui/icons-material/GetApp';
import Exports from './Exports';

const StripedDataGrid = styled(DataGridPro)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: "#F8F8F8",
  },
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: "#FFFFFF",
  },
}));

export default function ProductTable({hideSelectAll, onCellEditStop, page, rows , totalRows, onPageChange, handleOnRowClick, loading, header, allowExport, typesExport, setSelectionModel, selectionModel, tree, showPanel, getId }) {
    const apiRef = useGridApiRef();
    const columns = [
        {
            field: 'GTIN',
            headerName: 'GTIN',
            minWidth: 170,
            align: 'left',
        }
    ];

    let modifiers = tree ? 
        {
            'treeData': 'treeData',
            getTreeDataPath: (row) => row.path
        }
    :{};

    modifiers = showPanel !== undefined ? 
    {
        getDetailPanelContent: ({ row }) => showPanel(row), 'getDetailPanelHeight': () => 'auto'
    } : modifiers;
  
  return (
    <div style={{ height: 'calc(100% - 112px)', width: '100%' }}>
      {allowExport ? <Exports apiRef={apiRef} typesExport={typesExport} setSelectionModel={setSelectionModel} selectionModel={selectionModel} /> : ''}
      <StripedDataGrid
        {...modifiers}
        apiRef={apiRef}
        sx={{
            "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
              display: hideSelectAll !== undefined ? "none" : ""
            }
        }}
        rowModesModel={{ 1: { mode: GridRowModes.Edit } }}
        onCellEditCommit={(e) => onCellEditStop(e)}
        onSelectionModelChange={(newSelectionModel) => {
          if(setSelectionModel !== undefined) {
            setSelectionModel(newSelectionModel);
          }
        }}
        selectionModel={selectionModel}
        onRowClick={handleOnRowClick}
        loading={loading}
        rowCount={totalRows}
        paginationMode="server"
        onPageChange={onPageChange}
        rows={rows}
        columns={header === undefined ? columns : header}
        page={page}
        getRowId={(r) => getId !== undefined ? getId(r) : r.id}
        pageSize={25}
        rowsPerPageOptions={[25]}
        checkboxSelection
        disableSelectionOnClick
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
      />
    </div>
  );
}