import React from "react";
import { Typography, Box } from '@mui/material';

export default function Notification({success}) {
    const imageURL = success ? "UploadProduct/ok_icon.png" : "UploadProduct/ok_icon.png";
    const bgColor = success ? "#94D96E" : "#E16C43";
    const message = success ? "File uploaded successfully" : "File upload failed"

    return <Box sx={{
                marginLeft: "5%",
                marginRight: "5%",
                marginTop: "50px",
                display: "flex",
                gap: "10px",
                backgroundColor: `${bgColor}`,
                padding: "15px",
                justifyContent: "center",
                alignContent: "center"
            }}>
                    <Box 
                        component="img"
                        sx={{
                            height: 20,
                            width: 20
                        }}
                        src={imageURL}                  
                    />
                    <Typography color="#FFFFFF" fontSize="16px" fontWeight="400">{message}</Typography>
            </Box>

}   