import React, { useState, useEffect } from "react";
import { Grid, Box, Tab, Tabs, Typography, Button } from '@mui/material';
import Levels from "../../components/OnneList/Levels";

export default function LevelsExplanation() {
    return  <Grid container direction="column" rowSpacing={2}>
                <Grid item xs={12}>
                    Empty cell means the field is in the root level.
                </Grid>
                <Grid item container direction="row" rowSpacing={2} xs={12}>
                    <Grid item xs={3}>
                        <Levels level={1} />
                    </Grid>
                    <Grid item xs={9}>
                        Level 1
                    </Grid>
                </Grid>
                <Grid item container direction="row" rowSpacing={2} xs={12}>
                    <Grid item xs={3}>
                        <Levels level={2} />
                    </Grid>
                    <Grid item xs={9}>
                        Level 2
                    </Grid>
                </Grid>
                <Grid item container direction="row" rowSpacing={2} xs={12}>
                    <Grid item xs={3}>
                        <Levels level={3} />
                    </Grid>
                    <Grid item xs={9}>
                        Level 3
                    </Grid>
                </Grid>
            </Grid>
}