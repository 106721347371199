import React, { useState, useEffect } from "react";
import { Grid, Box, Tab, Tabs, TextField, Button, Typography, Checkbox, FormControlLabel} from '@mui/material';
import PropTypes from "prop-types";
import { useNavigate  } from 'react-router-dom';
import useToken from '../App/useToken';
import useUser from '../App/useUser';
import toolsUserApi from '../../tools/toolsUserApi';
import "./Login.css";
import tsLogo from "../../img/tslogo.png";
import tsBack from "../../img/loginback.png";
import gs1Logo from "../../img/gs1logo.png";
import Config from "../../config/config"
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

async function loginUser(credentials) {
    return fetch(Config.API_URL + "auth/login", {
        method: "POST",

        headers: {
            "Content-Type": "application/json",
        },

        body: JSON.stringify(credentials),
    }).then((data) => data.json());
}

function platformChange(event){
    console.log("We change to " )
}
let style = {
    welcome : {
        backgroundColor : "#3284C6", 
        paddingTop: "23%", 
        /*backgroundImage: `url(${tsBack})`, */
        backgroundPosition: 'bottom left',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    },
    loginText : {marginTop : "50px", textAlign: "justify"},
    login : {height: "100%" , paddingTop: "200px"},
    loginElem : {marginTop : "40px"},
}

export default function Login() {
    const [email, setUserName] = useState();
    const [password, setPassword] = useState();
    const [authenticated, setAuthenticated] = useState(false);
    const { token, setToken, tokenValid } = useToken();
    const { user, setUser } = useUser();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const [loginErrorMsg, setLoginErrorMsg] = useState("");
    const {getUserData} = toolsUserApi();
    

    useEffect(()=>{
        if(token && tokenValid == "true" && !authenticated) {
            setAuthenticated(true);
            
        }
    })

    useEffect(() => {
        async function getUserEffect() {
            const userData = await getUserData(token);
            
            if(userData.payload) {
                setUser(userData.payload.user);
                navigate('/product-browser');
            }
        }
        if(token && tokenValid == "true" && authenticated) {
            getUserEffect();
        }
    },[token, tokenValid, authenticated]);

    const showLoginError = () => {
        setLoginErrorMsg("Login Failed");
        setLoginError(true);
        setOpen(false);
    }

    const cleanLoginError = () => {
        setLoginErrorMsg("");
        setLoginError(false);
    }

    const handleToggle = () => {
        setOpen(!open);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        handleToggle();
        cleanLoginError();

        const token = await loginUser({
            email,
            password,
        });

        if(token.error) {
            showLoginError();
        }
        setToken(token.payload);
    };
    
    return (
        <div className="login-main">
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{height: '100%'}}
            >
                <Grid className="login-welcome" height={'100%'} direction="column" style={style.welcome} item xs={6} container alignItems="center">
                    <Box width={'60%'} component="img" sx={{ width: 'auto', height: 'auto' }} src={tsLogo}></Box>
                </Grid>
                <Grid className="login-wrapper" container direction="column" style={style.login} alignItems="center" item xs={6}>
                    <Box width={'80%'}>
                        <Box style={style.loginElem}>
                            <Typography color={"#3284C6"} variant="h4" component="h4">
                                Log In
                            </Typography>
                            <form style={style.loginElem} onSubmit={handleSubmit}>
                                <TextField error={loginError} helperText={loginErrorMsg} fullWidth style={style.loginElem} label="Email" variant="outlined" onChange={(e) => setUserName(e.target.value)}/>
                                <TextField fullWidth style={style.loginElem} label="Password" variant="outlined" onChange={(e) => setPassword(e.target.value)} type="password"/>
                                {/*<FormControlLabel style={style.loginElem} control={<Checkbox />} label="Remember me" />*/}
                                <Button fullWidth style={style.loginElem} type="submit" variant="contained">Log in</Button>
                                {/*<Button fullWidth style={style.loginElem}>Forgot Password</Button>*/}
                            </form>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}

