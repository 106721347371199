
export default function useToolsRenderSupport() {

    const currentDate = () => {
        var options = {
            year: "numeric",
            month: "2-digit",
            day: "numeric"
        };

        return new Date().toLocaleString(undefined,options) + "";
    }

    const checkDefaults = (mapping, fieldId, conversions) => {
        let value = undefined;
        
        if(conversions === true && mapping[fieldId].default !== undefined && mapping[fieldId].default.type !== undefined) {
            switch(mapping[fieldId].default.type) {
                case 'currentDate':
                    value = currentDate();
                    break;
                case 'string':
                    value = mapping[fieldId].default.value;
                    break;
            }
        }

        return value;
    }
    
    const renderPreview = (mapping, field, item, conversions = false) => {   
        let preview = '';

        if(field.group !== undefined){
            if(mapping[field.fieldId] !== undefined) {
                if(mapping[field.fieldId].value !== undefined) {
                    let sourceFieldId = mapping[field.fieldId].value;
                    preview = item[sourceFieldId] !== undefined ? item[sourceFieldId] : checkDefaults(mapping, field.fieldId, conversions) !== undefined ? checkDefaults(mapping, field.fieldId, conversions) : 'Not found';
                } else {
                    preview = checkDefaults(mapping, field.fieldId, conversions) !== undefined ? checkDefaults(mapping, field.fieldId, conversions) : "Not Found";
                }
            }
        } else {
            if(mapping[field.fieldId] !== undefined && mapping[field.fieldId][field.group] !== undefined) {
                if(mapping[field.fieldId][field.group].value !== undefined) {
                    let sourceFieldId = mapping[field.fieldId][field.group].value;
                    preview = item[sourceFieldId] !== undefined ? item[sourceFieldId] : checkDefaults(mapping, field.fieldId, conversions) !== undefined ? checkDefaults(mapping, field.fieldId, conversions) : 'Not found';
                } else {
                    preview = checkDefaults(mapping, field.fieldId, conversions) !== undefined ? checkDefaults(mapping, field.fieldId, conversions) : "Not Found";
                }
            }
        }

        return preview;     
    };

    return {
        renderPreview,
    };
}