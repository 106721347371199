import useToken from '../components/App/useToken';
import { useNavigate  } from 'react-router-dom';

export default function ToolsApi() {
    const { token, setTokenValid } = useToken();
    const navigate = useNavigate();

    const postCall = async (url, query, body) => {
        let newUrl = url;
        if(query !== undefined) {
            newUrl = newUrl + "?" + encodeGetParams(query);
        }
        
        const response = await fetch(newUrl, {
            method: "POST",
    
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token,
            },

            body: JSON.stringify(body),
        });
        let actualData = await response.json();
        
        if(response.status === 401) {
            navigate('/login');
        }

        return actualData;
    };

    const putCall = async (url, query, body) => {
        let newUrl = url;
        if(query !== undefined) {
            newUrl = newUrl + "?" + encodeGetParams(query);
        }
        
        const response = await fetch(newUrl, {
            method: "PUT",
    
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token,
            },

            body: JSON.stringify(body),
        });
        let actualData = await response.json();
        
        if(response.status === 401) {
            navigate('/login');
        }

        return actualData;
    };

    const getCall = async (url, query, body, tokenOverride) => {
        let newUrl = url;
        if(query !== undefined) {
            newUrl = newUrl + "?" + encodeGetParams(query);
        }

        let tokenUsed = token;
        if(tokenOverride) {
            tokenUsed = tokenOverride;
        }
        const response = await fetch(newUrl, {
            method: "GET",
    
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + tokenUsed,
            },

            body: JSON.stringify(body),
        });
        let actualData = await response.json();


        if(response.status === 401) {
            navigate('/login');
        }

        return actualData;
    };

    const deleteCall = async (url, query, body) => {
        let newUrl = url;
        if(query !== undefined) {
            newUrl = newUrl + "?" + encodeGetParams(query);
        }

        const response = await fetch(newUrl, {
            method: "DELETE",
    
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token,
            },

            body: JSON.stringify(body),
        });
        let actualData = await response.json();

        if(response.status === 401) {
            navigate('/login');
        }

        return actualData;
    };

    const encodeGetParams = p => 
        Object.entries(p).map(kv => kv.map(encodeURIComponent).join("=")).join("&");

    return {
        post: postCall,
        get: getCall,
        deleteCall,
        put: putCall,
    };
}