import React from "react";
import { Grid } from '@mui/material';
import ProductTitle from "./ProductTitle"
import {CircularProgress} from "@mui/material";

export default function LoadingSection() {

    const progressSection = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }

    return  <Grid container item width="100%" height="100%" direction="column">                
                <Grid item>
                    <ProductTitle/>                
                </Grid>
                <Grid item xs style={progressSection}>
                    <CircularProgress style={{width: "320px", height: "320px", color: "#d6e4ef"}} />
                </Grid>
            </Grid>
}   