import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from '../Login/Login';
import Workspace from "../Workspace/Workspace";
import Mapping from "../Mapping/Mapping";
import AddProducts from "../AddProducts/AddProducts";
import Reports from "../Reports/Reports";
import Bootstrap from "../Bootstrap/Bootstrap";
import Profile from "../Profile/Profile";
import PublishManager from "../PublishManager/PublishManager";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import AddDataModel from "../AddDataModel/AddDataModel";
import AddCustomDataModel from "../AddCustomDataModel/AddCustomDataModel";
import store from '../../redux/store';
import { Provider } from 'react-redux';
import TargetModelsManager from "../TargetModelsManager/TargetModelsManager";
import Dashboard  from "../Dashboard/Dashboard"
import Conversions from "../Conversions/Conversions";

// Create a client
const queryClient = new QueryClient()


function App() {
    return (
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <Routes>
                  <Route path="/" element={<Login />}></Route>
                  <Route path="/login" element={<Login />}></Route>
                  <Route path="/product-browser" element={<Bootstrap title="Workspace"><Workspace /></Bootstrap>}></Route>
                  <Route path="/mapping" element={<Bootstrap><Mapping /></Bootstrap>}></Route>
                  <Route path="/mapping/:gtin" element={<Bootstrap><Mapping /></Bootstrap>}></Route>
                  <Route path="/add-products" element={<Bootstrap><AddProducts /></Bootstrap>}></Route>
                  <Route path="/reports" element={<Bootstrap><Reports /></Bootstrap>}></Route>
                  <Route path="/add-custom-datamodel" element={<Bootstrap><AddCustomDataModel /></Bootstrap>}></Route>
                  <Route path="/target-models" element={<Bootstrap><TargetModelsManager /></Bootstrap>}></Route>
                  <Route path="/profile" element={<Bootstrap><Profile /></Bootstrap>}></Route>
                  <Route path="/conversions" element={<Bootstrap><Conversions /></Bootstrap>}></Route>
                  <Route path="/dashboard" element={<Bootstrap><Dashboard /></Bootstrap>}></Route>
                  <Route path="/publish-manager" element={<Bootstrap title="Publish Manager"><PublishManager /></Bootstrap>}></Route>
                </Routes>
            </BrowserRouter>
          </QueryClientProvider>
        </Provider>
      );
    }
export default App;
