import React, { useState, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { Grid, Box, Typography, NativeSelect, MenuItem } from "@mui/material";
import useDataModel from "../App/useDataModel";
import { useSelector } from 'react-redux';
import { setSelectedDataModel } from "../../features/selectedDataModelSlice";
import { setGpc } from "../../features/gpcSlice";
import User from "./User";

export default function Title({name}) {
    const dispatch = useDispatch();
    const dataModelStorage = useSelector((state) => state.dataModel.value);
    const selectedDataModelStorage = useSelector((state) => state.selectedDataModel.value);
    const gpcStorage = useSelector((state) => state.gpc.value);
    const [selectedDataModelIndex, setSelectedDataModelIndex] = useState(0);
    const pagesSelectBanned = ["Add Products", "Target Models", "Profile"];

    useEffect(() => {
        if(dataModelStorage !== undefined){
            dispatch(setSelectedDataModel(dataModelStorage[selectedDataModelIndex]))
            if(dataModelStorage[selectedDataModelIndex].gpcs.length > 0) {
                dispatch(setGpc(dataModelStorage[selectedDataModelIndex].gpcs[0].brick))
            } else {
                dispatch(setGpc(null))
            }
        }
    }, [selectedDataModelIndex])

    const handleSelectedDataModelChange = (value) => { 
        setSelectedDataModelIndex(value)
    }
    
    const handleGpcChange = (value) => {
        dispatch(setGpc(value))
    }

    let mainStyle = {
        height : "75px", 
        minHeight : "75px",
        backgroundColor: "#FFF",
        boxShadow: "1px 2px 6px rgba(0,0,0,0.3)",
        paddingLeft: "20px",
        alignItems: "center"
    };

    let titleStyle = {
        fontFamily: 'Figtree',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '22px',
        lineHeight: '26px',
        alignItems: 'center',
        color: '#4A4F53',
        width: 'auto'
    }

    let optionStyle = {
        marginLeft: "15px",
        marginRight: "15px"
    }


    return  dataModelStorage !== undefined
            ?   <Grid container direction="row" item style={mainStyle}>
                    <Grid item xs={8}>
                        <Typography variant="h1" component="h1" style={titleStyle} >
                            {name}
                        {!pagesSelectBanned.includes(name) ?
                            <NativeSelect 
                                style={optionStyle}
                                value={selectedDataModelIndex}
                                label=""
                                onChange={(e) => {handleSelectedDataModelChange(e.target.value);}}
                            >
                                {dataModelStorage.map((value, key) => (
                                    <option key={key} value={key}>{value.type + ' v' + value.version}</option>
                                )) }
                            </NativeSelect>
                            :
                            ''
                        }
                        {!pagesSelectBanned.includes(name) ?
                            selectedDataModelStorage !== undefined && selectedDataModelStorage !== null && selectedDataModelStorage.gpcs.length > 0 ?
                                <NativeSelect
                                    id="select-gpc"
                                    value={gpcStorage}
                                    label=""
                                    sx={{maxWidth: "200px", textOverflow: "ellipsis"}}
                                    onChange={(e) => {handleGpcChange(e.target.value);}}
                                >
                                    {selectedDataModelStorage.gpcs.map((value, key) => (
                                        <option key={key} value={value.brick}>{value.brick} ({value.brickName})</option>
                                    ))}
                                </NativeSelect>
                            
                            : 
                                ''
                            :
                            ''
                        }
                        
                        </Typography>
                    </Grid>
                    <Grid item xs={4} height={"100%"} >
                        <User />
                    </Grid>
                </Grid>
            : "" ;
}