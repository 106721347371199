import React, { useState, useEffect } from "react";
import { Grid, Box, Button, TextField, Typography, Autocomplete, Select, MenuItem, Switch } from '@mui/material';
import { DataGridPro, GridActionsCellItem, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import GpcSelector from './GpcSelector';
import toolsMappingApi from "../../tools/toolsMappingApi";
import { useSelector } from 'react-redux';
import ToolsNotifications from "../../tools/ToolsNotifications";

export default function OnneFormula({mapField, unmapField, field, changeMapping, sourceFields, renderOption, mapping}) {
    const selectedDataModel = useSelector((state) => state.selectedDataModel.value);
    const {success, error} = ToolsNotifications();
    const {getGpcsMapping, deleteMapping , addGpcsMapping} = toolsMappingApi()
    const [rows, setRows] = useState([])
    const [selectedOptionDefault, setSelectedOptionDefault] = useState("string")
    const [defaultValue, setDefaultValue] = useState("")
    const [loading, setLoading] = useState(true);
    const [fillZeros, setFillZeros] = useState(undefined);
    const [gpcs, setGpcs] = useState(undefined);

    useEffect(() => {
        setLoading(false)
    }, [rows])

    

    useEffect(() => {
        // set the mapping data
        console.log(fillZeros);
        if(fillZeros !== undefined && mapping !== undefined && mapping !== undefined) {
            let newObject = mapping;
            if(typeof newObject !== 'undefined' && (newObject.zeros === undefined || newObject.zeros !== fillZeros)){
                newObject.zeros = fillZeros;
                changeMapping(field, newObject, true);
            }  
        }
    }, [fillZeros])

    useEffect(() => {
        if(field !== undefined) {
            let newRows = []
            if(field.hasOwnProperty('uom') && field.uom === true) {
                newRows.push({sourceField: field.uomValue, targetField: "uom"})
            } 
            setRows(newRows) 
            if(field.gpcs) {
                getGpcsMapping(selectedDataModel.type, selectedDataModel.version, field.fieldId).then(data => {
                    if(!data.error) {
                        setGpcs(data.payload);
                    }
                })
            }
        } 
    }, [field])

    useEffect(() => {
        if(mapping !== undefined) {
            if(mapping.default !== undefined) {
                if(mapping.default.type !== undefined) {
                    setSelectedOptionDefault(mapping.default.type);
                }
                if(mapping.default.value !== undefined) {
                    setDefaultValue(mapping.default.value);
                }
            } 
            
            if(mapping.zeros !== undefined) {
                console.log("SETTING" + mapping.zeros)
                setFillZeros(mapping.zeros);
            } 
        } 
    }, [mapping])

    const setDefault = () => {
        let newObject = {}
        if(mapping === undefined){
            newObject.value = undefined;
            if(field.lang) {
                newObject.originalId = field.originalId
                newObject.lang = undefined
            } else {
                if(field.position !== undefined) {
                    newObject.originalId = field.originalId
                    newObject.position = field.position
                }
            }
        }  else {
            newObject = mapping;
        }
        newObject.default = {type: selectedOptionDefault, value: selectedOptionDefault === "string" ? defaultValue : ""}
        changeMapping(field, newObject, true);
    }

    const clearDefault = () => {
        if(mapping !== undefined && mapping.default !== undefined) {
            let newObject = mapping;
            delete newObject.default; 
            setDefaultValue("");
            changeMapping(field, newObject, true);  
        }
    }

    //TODO
    const bulkMap = () => {
        mapField(field, gpcs)
    }

    const bulkUnmap = () => { 
        let bulk = true;
        unmapField(field, bulk)
    }

    const renderSourceFieldColumn = (row) => {
        return  <Autocomplete
                    renderOption={(props, option) => renderOption(props, option)}
                    disablePortal
                    clearOnBlur
                    id="combo-box-demo"
                    value={mapping !== undefined ? mapping[row.targetField] !== undefined ? mapping[row.targetField] : '' : ''}
                    options={Object.keys(sourceFields).map((key) => (
                                {label: key, id: key}
                            ))}
                    sx={{ width: 300, backgroundColor: "white" }}
                    ListboxProps={{ style: { maxHeight: 130 } }}
                    onChange={(event, newValue) => {
                        let newObject = {}
                        if(mapping === undefined){
                            newObject.value = undefined;
                            if(field.lang) {
                                newObject.originalId = field.originalId
                                newObject.lang = undefined
                            } else {
                                if(field.position !== undefined) {
                                    newObject.originalId = field.originalId
                                    newObject.position = field.position
                                }
                            }
                        }  else {
                            newObject = mapping;
                        }
                        newObject[row.targetField] = newValue.label

                        changeMapping(field, newObject, true);                        
                    }}
                    renderInput={(params) => 
                        <TextField {...params} 
                            placeholder="Search"
                            variant="standard" 
                        />}
                />
    }

    const columns = [
        {
            field: 'sourceField',
            headerName: 'Source Field',
            align: 'left',
            minWidth: 150,
            renderHeader: () => (
                <Typography variant="selected">Source Field</Typography>
            ),
            renderCell: (params) => renderSourceFieldColumn(params.row)
        },
        {
            field: 'targetField',
            headerName: 'Field Id',
            align: 'left',
            minWidth: 150,
        },
    ]

    const defaultOptions = [
        {
            key: "string",
            value: "Custom Value"
        }, 
        {
            key: "currentDate",
            value: "Today"
        }
    ]


    const buttonStyle = {
        backgroundColor: "#FFF",
        border: "solid 1px #1EB1E0",
        color: "#3284C6",
        fontSize: "12px",
        fontWeight: "400",
        '&:hover': {
            backgroundColor: "rgba(30, 177, 224, 0.1)"
        }
    }

    const buttonStyleDel = {
        backgroundColor: "#FFF",
        border: "solid 1px #E16C43",
        color: "#E16C43",
        fontSize: "12px",
        fontWeight: "400",
        '&:hover': {
            backgroundColor: "rgba(225, 108, 67, 0.1)"
        }
    }

    return  <Box sx={{height: 700, width: "100%", borderRadius: "4px"}}>
                <Grid container xs={12} columnSpacing={1}>
                    <Grid item xs={field.gpcs !== undefined ? 6 : 12}>
                        <Typography sx={{width: "100%", marginBottom: '5px'}} fontSize="20px" fontWeight="400" color="#4A4F53">Unit of measure</Typography>
                        <Box sx={{height: 400, width: "100%", borderRadius: "4px"}} style={{marginBottom: '20px'}}>
                            <DataGridPro
                                loading={loading}
                                disableColumnMenu
                                hideFooterRowCount
                                hideFooterPagination
                                hideFooter
                                rows={rows}
                                columns={columns}
                                disableSelectionOnClick
                                getRowId={(r) => r.targetField}
                                sx={{overflow: 'visible', zIndex: '2'}}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        {field.gpcs !== undefined ?
                            <Box>
                                <Typography fontSize="20px" fontWeight="400" color="#4A4F53" sx={{marginBottom: '5px'}}>Classification data</Typography>
                                <GpcSelector field={field} setGpcs={setGpcs} selectedGpcs={gpcs} selectable={mapping !== undefined}/>
                            </Box>
                            : '' 
                        }
                    </Grid>
                </Grid>
                <Grid container direction="row" xs={12} columnSpacing={1} rowSpacing={1}>
                    <Grid item xs={6}>
                        <Switch style={{color:"primary"}} value={fillZeros} checked={fillZeros} onChange={(e) => setFillZeros(e.target.checked)} />
                        Complete with zeros
                    </Grid>
                    <Grid item xs={6}>
                        {field.gpcs !== undefined ?
                            <Button 
                                sx={buttonStyle} 
                                onClick={gpcs === undefined || gpcs.length === 0 ? bulkUnmap : bulkMap}
                                disabled={mapping === undefined}
                                fullWidth
                            >
                                {gpcs === undefined || gpcs.length === 0 ? "Unmap for all gpcs" : "Bulk Mapping"}
                            </Button>
                            : '' 
                        }
                    </Grid>
                </Grid>
                <Typography fontSize="20px" fontWeight="400" color="#4A4F53">Default Value</Typography>
                <Grid container direction="row" xs={12} columnSpacing={1} rowSpacing={1}>
                    <Grid item xs={6}>
                        <Select
                            value={selectedOptionDefault}
                            label=""
                            onChange={(e) => {setSelectedOptionDefault(e.target.value)}}
                            fullWidth
                        >
                            {defaultOptions.map((op) => (
                                <MenuItem key={op.key} value={op.key}>{op.value}</MenuItem>
                            )) }
                        </Select>
                    </Grid>
                    <Grid item xs={6}>
                        {selectedOptionDefault === "string" ?
                            <TextField 
                                label="Value"
                                id="name-basic" 
                                variant="outlined" 
                                value={defaultValue}
                                onChange={(e) => {setDefaultValue(e.target.value)}}
                                fullWidth
                            />
                        :
                            ''
                        }
                    </Grid>
                    <Grid item container xs={12} columnSpacing={1}>
                        <Grid item xs={6}>
                                <Button 
                                    sx={buttonStyle} 
                                    onClick={setDefault}
                                    fullWidth
                                    disabled={selectedOptionDefault === "string" && defaultValue === ""}
                                >
                                    Set Default
                                </Button>
                        </Grid>
                        <Grid item xs={6}>
                                {mapping !== undefined && mapping.default !== undefined ? 
                                        <Button 
                                            sx={buttonStyleDel} 
                                            onClick={clearDefault}
                                            fullWidth
                                        >
                                            Clear Default
                                        </Button> 
                                    :
                                        ''
                                }
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        
                    </Grid>
                </Grid>
            </Box>
}