import { useDispatch } from 'react-redux';
import { setOpenUpdateNotification } from "../features/openUpdateNotificationSlice";

export default function ToolsUpdateNotification() {
    const dispatch = useDispatch();

    const open = (description, updateId) => {
        dispatch(setOpenUpdateNotification({open: true, description: description, updateId: updateId }))
    }

    const close = () => {
        dispatch(setOpenUpdateNotification({open: false,  description: undefined, updateId: undefined }));
    }

    return {
        open,
        close,
    };
}