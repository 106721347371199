import React, { useState, useEffect } from "react";
import { Grid, InputAdornment, Button, TextField, Typography, Autocomplete } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export default function OnneHelperHeader({setSelectedModel, type, version, options, defaultId}) {
    const [processedOpt, setProcessedOpt] = useState([]);

    
    useEffect(() => {
        if(defaultId !== undefined) {
            handleChange(defaultId)
        }
    }, [defaultId])

    const handleChange = (value) => {
        let selected = undefined
        if(value !== undefined && value !== null) {
            options.map((opt) => {
                if(opt.fieldId === value) {
                    selected = opt;
                }
            })
        }

        setSelectedModel(selected);
    }

    useEffect(()=> {
        if(options !== undefined) {
            let processed = [];
            options.map((opt) => {
                processed.push({label: opt.longName !== undefined ? opt.longName : '', id: opt.fieldId})
            })
            setProcessedOpt(processed);
        }
    }, [options])

    return  <Grid item container direction="row" xs={12} height={"100%"} alignItems={"center"} justifyItems={"center"} >
                <Grid item container direction="column" xs={4} alignItems={"center"} justifyItems={"center"}>
                    <Grid item>
                        <Typography variant="helperType">{type} {version}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="helperDB">Database</Typography>
                    </Grid>
                </Grid>
                <Grid item container direction="column" xs={8} alignItems={"center"} justifyItems={"center"} >
                    <Grid item>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={processedOpt}
                        sx={{ width: 300, backgroundColor: "white" }}
                        onChange={(event, newValue) => {
                            handleChange(newValue.id);
                        }}
                        renderInput={(params) => 
                            <TextField {...params} 
                                label="Search" 
                            />}
                    />
                    </Grid>
                </Grid>
            </Grid>
}