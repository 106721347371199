import React, { useEffect } from "react";
import { Grid } from '@mui/material';
import ProductTitle from "./ProductTitle";
import ProductWarnings from "./ProductWarnings";
import Notification from "./Notification";
import WorkspaceSection from "./WorkspaceSection";
import MappingSection from "./MappingSection";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import useToolsCustomDatamodelUpload from '../../tools/toolsCustomDatamodelUpload';
import { setRefreshDataModels } from '../../features/refreshDataModelsSlice';
import { useNavigate  } from 'react-router-dom';

export default function CustomDataModelUploadResult({customDataModel}) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { postCustomDataModel } = useToolsCustomDatamodelUpload();
    const targetModel = useSelector((state) => state.targetModel.value);

    const saveTargetModel = () => {
        if(targetModel !== undefined && targetModel.name !== undefined && targetModel.name !== '' && targetModel.data !== undefined && targetModel.data.length > 0) {
            postCustomDataModel(targetModel).then((data) => {
                dispatch(setRefreshDataModels(true));
                navigate('/product-browser')
            });
        }
    }

    /* useEffect(() => {
        console.log(targetModel)
    }, [targetModel]) */

    const rightDivider = {
        borderRight: "solid 1px #666E71"
    }

    return  <Grid container item xs={12} width="100%" height="100%">
                <Grid item xs={3} style={rightDivider}>
                    <ProductTitle title={"Create Target Model"}/>
                    <ProductWarnings/>
                    <Notification success={true}/>
                </Grid>
                <Grid item xs={5} style={rightDivider}>
                    <MappingSection customDataModel={customDataModel}/>
                </Grid>
                <Grid item container xs={4}>
                    <WorkspaceSection saveTargetModel={saveTargetModel} />
                </Grid>
            </Grid>
}   