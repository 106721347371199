import ToolsApi from './ToolsApi';
import Config from '../config/config';

export default function toolsUserApi() {
    const { post, get, deleteCall } = ToolsApi();

    const getUserData = async (token) => {

        let url = Config.API_URL + "api/user";

        let body = undefined
        let query = undefined
        return get(url, query, body, token);
    };

    return {
        getUserData,
    };
}