import ToolsApi from './ToolsApi';
import Config from '../config/config';

export default function toolsPublishApi() {
    const { post, get } = ToolsApi();

    const postGln = async (body) => {
        let url = Config.API_URL + "api/gln";
        
        let query = undefined
        return post(url, query, body);
    };

    const getAllGlns = async () => {

        let url = Config.API_URL + "api/glns"
        
        let body = undefined;
        let query = undefined;
        return get(url, query, body);
    }

    const publishGtin = async (body, id) => {
        let url = Config.API_URL + "api/source-item/" + id + "/register";

        /*
            dataPool: "TS",
            publishTo: glnobjetivo
            autopublish: true,
            sender: "8720299105218"
            dataModel: el seleccionado
            version: la seleccionada
        */
        
        let query = undefined
        return post(url, query, body);
    }; 

    return {
        postGln,
        getAllGlns,
        publishGtin,
    };
}