import { createSlice } from "@reduxjs/toolkit";

export const onneMappingFilterSlice = createSlice({
    name: 'filters',
    initialState: {
        value: {mappedState: 0, dataType: 0, mappedType: 0},
    },
    reducers: {
        setFilters: (state, action) => {
            state.value = action.payload;
        }
    }
});

export const { setFilters } = onneMappingFilterSlice.actions;

export default onneMappingFilterSlice.reducer;