import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { Grid, NativeSelect, FormControl, FormLabel, RadioGroup, FormControlLabel, Divider, Button, Switch, Typography } from '@mui/material';
import { setClassification, setClassificationValue, setKeyValue } from "../../features/optionsSlice";
import { styled } from '@mui/material/styles';
import OnneCheckButton from "../OnneList/OnneCheckButton";
import { CompareSharp } from "@mui/icons-material";

export default function MappingOptions({items, selectedKey, setSelectedKey, classification, setClassification}) {
    const sectionStyle = {
        width: "auto",
        height: "auto",
        margin: "0px 20px 20px 20px",
        padding: "10px 20px 10px 20px",
        gap: "10px"
    }

    let optionStyle = {
        marginLeft: "15px",
        marginRight: "15px"
    }

    const FormStyled = styled(FormControl)(() => ({
        marginLeft: '20px'
    }));

    const RadioGroupStyled = styled(RadioGroup)(() => ({
        marginLeft: '0px'
    }));

    const ButtonStyled = styled(Button)(() => ({
        paddingTop: '20px',
        marginLeft: '20px'
    }));

    const [hasClassification, setHasClassification] = useState(true);

    const handleSelectedKeyChange = (value) => {
        setSelectedKey(value);
    }

    const handleSelectedClassificationChange = (value) => {
        setClassification(value);
    }

    const handleHasClassification = (value) => {
        setHasClassification(value);
        if(!value) {
            setClassification('');
        }
    }

    useEffect(() => {
        if(classification !== "" && classification !== undefined && hasClassification === false) {
            setHasClassification(true)
        }
    }, [classification])

    /* useEffect(() => {
        console.log(hasClassification);
        console.log(classification);
    }, [hasClassification]) */


    return <Grid container direction="column" rowSpacing={2}>
            <Grid item container direction="row" xs={12} justifyContent={"center"}>
                <Grid item xs={6}><Typography variant="addTitles">Select Key field (gtin, ean, etc):</Typography></Grid>
                <Grid item xs={6}>
                    <NativeSelect style={optionStyle}
                        value={selectedKey}
                        onChange={(e) => handleSelectedKeyChange(e.target.value)}
                        label=""
                    >
                        <option value={""}>None</option>
                        {items.map((value, key) => (
                            <option key={key} value={value}>{value}</option>
                        )) }
                    </NativeSelect>
                </Grid>
            </Grid>
            {/* <Grid item container direction="row" xs={12} justifyContent={"center"}>
                <Grid item xs={6}><Typography variant="addTitles">Has Classification:</Typography></Grid>
                <Grid item xs={6}>
                    <Switch style={{color:"primary"}} checked={hasClassification} onChange={(e) => handleHasClassification(e.target.checked)} />
                </Grid>
            </Grid> */}

            {hasClassification === true ?
                <Grid item container direction="row" xs={12} justifyContent={"center"}>
                    <Grid item xs={6}><Typography variant="addTitles">Select classification field:</Typography></Grid>
                    <Grid item xs={6}>
                        <NativeSelect style={optionStyle}
                            value={classification}
                            label=""
                            onChange={(e) => {handleSelectedClassificationChange(e.target.value)}}
                        >
                            <option value={""}>None</option>
                            {items.map((value, key) => (
                                <option key={key} value={value}>{value}</option>
                            )) }
                        </NativeSelect>       
                    </Grid>
                </Grid>
            :
                ''
            }
        </Grid>
}   