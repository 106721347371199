import React, { useState, useEffect } from "react";
import { Grid, Box, Tab, Tabs, Typography, Button } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

export default function CheckExplanation() {
    return  <Grid container direction="column" rowSpacing={2}>
                <Grid item xs={12}>
                    This column represents the field status.
                </Grid>
                <Grid item container direction="row" rowSpacing={2} xs={12}>
                    <Grid item xs={3}>
                        <Typography variant="orange"><WarningAmberIcon fontSize="large"/></Typography>
                    </Grid>
                    <Grid item xs={9}>
                        This icon represents a mandatory field that is empty.
                    </Grid>
                </Grid>
                <Grid item container direction="row" rowSpacing={2} xs={12}>
                    <Grid item xs={3}>
                        <AddCircleIcon fontSize="large"/>
                    </Grid>
                    <Grid item xs={9}>
                        This icon means that we are managing a multi value field and you can use it to add more values.
                    </Grid>
                </Grid>
            </Grid>
}