import { useState } from "react";
import { Grid, Box, Typography, Button, TextField } from '@mui/material';
import Level1 from '../../img/levels/level1.png'
import Level2 from '../../img/levels/level2.png'
import Level3 from '../../img/levels/level3.png'

export default function Levels({level}) {
    const [levels, setLevels] = useState([undefined, Level1, Level2, Level3])

    return  <Grid container>
                {levels.map((levelIcon, i) => (
                    i <= level ? <Box key={i} component="img" style={{marginRight: '3px'}} src={levelIcon}></Box>  : ''
                ))}
            </Grid>
}