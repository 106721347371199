import { createSlice } from "@reduxjs/toolkit";

export const selectedDataModelSlice = createSlice({
    name: 'selectedDataModel',
    initialState: {
        value: undefined,
    },
    reducers: {
        setSelectedDataModel: (state, action) => {
            state.value = action.payload;
        },
    }
});

export const { setSelectedDataModel } = selectedDataModelSlice.actions;

export default selectedDataModelSlice.reducer;