import { createSlice } from "@reduxjs/toolkit";

export const productUploadSlice = createSlice({
    name: 'isProductUploading',
    initialState: {
        value: false,
    },
    reducers: {
        setIsProductUploading: (state, action) => {
            state.value = action.payload;
        }
    }
});

export const { setIsProductUploading } = productUploadSlice.actions;

export default productUploadSlice.reducer;