import { useState, useEffect } from "react";
import { Grid, Box, Button, TextField, Tooltip, InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { useSelector } from 'react-redux';
import toolsDataModelApi from "../../../tools/toolsDataModelApi";
import ToolsNotifications from "../../../tools/ToolsNotifications";
import usePreview from "./usePreview";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import useMapping from "./useMapping";

export default function usePicklist() {
    const { showRaw } = usePreview();
    const { success, error } = ToolsNotifications();
    const { postPicklistRules, getPicklistRulesMapped} = toolsDataModelApi();
    const [picklist, setPicklist] = useState(undefined);
    const [picklistConversions, setPicklistConversions] = useState(undefined);
    const [rowPicklist, setRowPicklist] = useState(undefined);
    const selectedDataModel = useSelector((state) => state.selectedDataModel.value);
    const gpc = useSelector((state) => state.gpc.value);

    const showUpdateMessage = () => {
        let msg = "Conversions updated successfully";
        success(msg);
    }

    const showUpdateMessageErr = () => {
        let msg = "Conversions updated error";
        error(msg);
    }

    const showUpdateMessageServerErr = () => {
        let msg = "Server error";
        error(msg);
    }

    useEffect(() => {
        if(picklist !== undefined && selectedDataModel.type !== undefined && selectedDataModel.version !== undefined && gpc !== undefined) {
            getPicklistRulesMapped(selectedDataModel.type, selectedDataModel.version, gpc).then((data) => {
                if(!data.error) {
                    setPicklistConversions(data.payload);
                }
            })
        }
    }, [picklist])

    const changePicklist = (value, row, mapping, selectedPreview) => {
        if(mapping[row.fieldId] !== undefined && mapping[row.fieldId].value !== undefined) {
            let conversionsLocal = {
                rules: {
                }
            }
            conversionsLocal.rules = picklistConversions[row.picklist] !== undefined ? picklistConversions[row.picklist] : {};
            Object.keys(conversionsLocal.rules).map((key) => {
                if(conversionsLocal.rules[key] === value) {
                    delete conversionsLocal.rules[key];
                }
            })
            conversionsLocal.rules[showRaw(row, mapping, selectedPreview)] = value
            
            postPicklistRules(selectedDataModel.type, selectedDataModel.version, gpc, row.picklist, conversionsLocal).then((data) => {
                if(!data.error) {
                    let copyOfObject = { ...picklistConversions };
                    Object.keys(picklistConversions[row.picklist]).map((key) => {
                        if(picklistConversions[row.picklist][key] === value) {
                            delete copyOfObject[row.picklist][key];
                        }
                    })
                    copyOfObject[row.picklist][showRaw(row, mapping, selectedPreview)] = value;
                    setPicklistConversions(copyOfObject) 
                    
                    showUpdateMessage();
                }else {
                    showUpdateMessageErr();
                }      
            }).catch((error) => {
                showUpdateMessageServerErr();
            })
        }
    }

    const getComponentPicklist = (row, mapping, selectedPreview) => {
        let component = ''
        if(picklist !== undefined) {
            if(row.multilingual !== true && row.multivalue !== true && row.picklist !== undefined && row.picklist !== null && picklist[row.picklist] !== undefined) {
                // console.log(row)
                // console.log(mapping);
                // console.log(showRaw(row, mapping, selectedPreview));
                // console.log(picklistConversions)
                component = <Grid container direction={"row"} columnSpacing={1} alignItems="center" alignContent={"center"}>
                                <Grid item xs={10}>
                                    {mapping[row.fieldId] !== undefined && mapping[row.fieldId].value !== undefined &&  showRaw(row, mapping) !== 'Not found' ? 
                                        <FormControl fullWidth variant="standard">
                                            <InputLabel id="demo-simple-select-label">Select value</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={picklistConversions !== undefined && picklistConversions[row.picklist] !== undefined && picklistConversions[row.picklist][showRaw(row, mapping, selectedPreview)] !== undefined ? picklistConversions[row.picklist][showRaw(row, mapping, selectedPreview)] : ''}
                                                label="Select value"
                                                onChange={(event) => changePicklist(event.target.value, row, mapping, selectedPreview)}
                                                displayEmpty
                                                renderValue={(value) => {
                                                    if (value === '') {
                                                        return <em style={{color: "black"}}>Select Value</em>;
                                                    }
                                        
                                                    return value;
                                                }}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {Object.keys(picklist[row.picklist]).map((key) => (
                                                    <MenuItem value={picklist[row.picklist][key].code}>{picklist[row.picklist][key].code}</MenuItem>
                                                )) }
                                            </Select>
                                        </FormControl>
                                    
                                    : 
                                        'Value need to convert'
                                    }
                                </Grid>
                                <Grid item xs={2}>
                                    <GridActionsCellItem
                                        icon={<FormatListBulletedIcon />}
                                        label="Picklist"
                                        onClick={() => setRowPicklist(row)}
                                    />
                                </Grid>
                            </Grid>
            }
        }
        return component;
    }

    return {
        getComponentPicklist,
        picklist,
        setPicklist,
        picklistConversions,
        setPicklistConversions,
        rowPicklist,
        setRowPicklist,

    };
}