import { createSlice } from "@reduxjs/toolkit";

export const gpcSlice = createSlice({
    name: 'gpc',
    initialState: {
        value: null,
    },
    reducers: {
        setGpc: (state, action) => {
            state.value = action.payload;
        },
    }
});

export const { setGpc } = gpcSlice.actions;

export default gpcSlice.reducer;