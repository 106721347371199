import React, { useState, useEffect } from "react";
import CheckExplanation from "./HelpComponents/CheckExplanation";
import LevelsExplanation from "./HelpComponents/LevelsExplanation";

const CollapseExplanation = () => {

}

const RenderPreviewExplanation = () => {

}

export const steps = {
    'Dashboard': [
        {
            target: '.totalClass',
            content: 'holis'
        },
        {
            target: '.notClass',
            content: 'holasdasda'
        },
    ],
    'Mapping': [
        {
            disableBeacon: true,
            target: '.mappingFiltersGtin',
            content: 'The GTIN used to preview your data is set here, select any Item you want to see its data in the mapping zone.'
        },
        {
            disableBeacon: true,
            target: '.mappingFiltersStatus',
            content: 'Here you can filter fields by different options, like mapped, mandatory or its type.'
        },        
        {
            disableBeacon: true,
            target: '.datagrid',
            content: 'This is the mapping table, here you can map any source field to any target field loaded in the system.'
        },
        
        {
            disableBeacon: true,
            target: '.cellLevelsClass',
            content: <LevelsExplanation />,
        },
        {
            disableBeacon: true,
            target: '.cellCheckClass',
            content: <CheckExplanation />,
        },
        {
            disableBeacon: true,
            target: '.cellAttributeClass',
            content: 'This field represents the origin column in the source data model. You can search by name and set the correct value.'
        },
        {
            disableBeacon: true,
            target: '.cellCollapseClass',
            content: 'This column represents the status of a collapsible field and also allows you to group/split the values'
        },
        {
            disableBeacon: true,
            target: '.cellSourceClass',
            content: 'This field represents the target model destination value.'
        },
        {
            disableBeacon: true,
            target: '.cellMapClass',
            content: 'This slider defines the mapping state and also allows you to map/unmap the field.'
        },
        {
            disableBeacon: true,
            target: '.cellFormulaClass',
            content: 'Formula column opens a modal that allows to set some different attributes as default value, uom or the list of gpcs where the mapping is set.'
        },
        {
            disableBeacon: true,
            target: '.cellRenderSourceClass',
            content: 'This column shows the raw data from the source file.'
        },
        {
            disableBeacon: true,
            target: '.cellRenderPreviewClass',
            content: 'This column shows the data after all the conversions or default configurations. If the field has a close attribute values a picklist value select will be available here.'
        },
    ],
}






