import { configureStore } from '@reduxjs/toolkit'
import dashboardReducer from './features/dashboardSlice'
import { getDefaultMiddleware } from '@reduxjs/toolkit';

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false
})

export default configureStore({
  middleware: customizedMiddleware,
  reducer: {
    dashboard: dashboardReducer,
  },
})