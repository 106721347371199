import { createSlice } from "@reduxjs/toolkit";

export const targetModelSlice = createSlice({
    name: 'targetModel',
    initialState: {
        value: undefined,
    },
    reducers: {
        setTargetModel: (state, action) => {
            state.value = action.payload;
        },
    }
});

export const { setTargetModel } = targetModelSlice.actions;

export default targetModelSlice.reducer;