import React, { useState, useEffect } from "react";
import { Grid, Box, Button, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import ToolsUpdateNotification from "../../tools/ToolsUpdateNotification";

let titleStyle = {
    fontFamily: 'Figtree',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '22px',
    lineHeight: '26px',
    alignItems: 'center',
    color: '#4A4F53',
    width: 'auto'
}

export default function UpdateNotification() {
    const upd = useSelector((state) => state.openUpdateNotification.value);
    const { close } = ToolsUpdateNotification();

    const handleUpdateNotificationClose = () => {
        close();
    }

    return  <Grid container direction="column" sx={{padding: '25px'}} rowSpacing={1}>
                <Grid item>
                    <Typography variant="h1" component="h1" style={titleStyle}>
                        Update Notification
                    </Typography>
                </Grid>
                <Grid item>
                    {upd.description}
                </Grid>
                <Grid item container justifyContent="space-around">
                    <Grid container item justifyContent={'flex-start'} xs={6}>
                        <Button onClick={handleUpdateNotificationClose} variant="main">Close</Button>
                    </Grid>
                    <Grid container item justifyContent={'flex-end'} xs={6}>
                        <Button variant="main">See details</Button>
                    </Grid>
                </Grid>
            </Grid>
             
}