import ToolsApi from './ToolsApi';
import Config from '../config/config';

export default function toolsExportApi() {
    const { post, deleteCall } = ToolsApi();

    const exportValidationResults = async (type, version, gpc, ids) => {
        let url = Config.API_URL + "api/source-items/validation/results/download";
        
        let body = {
            "dataModel": type,
            "version": version,
            "items" : ids,
        }
        if(gpc !== undefined && gpc !== null) {
            body.gpcCode = gpc;
        }
        let query = undefined
        return post(url, query, body);
    };

    const postExportSourceItem = async (type, version, id, gpc) => {
        let url = Config.API_URL + "api/source-item/" + id + "/convert/pmdf";
        
        let body = {
            "dataModel":type,
            "version": version
        }
        if(gpc !== undefined && gpc !== null) {
            body.gpcCode = gpc;
        }
        let query = undefined
        return post(url, query, body);
    };

    const deleteSourceItem = async (id) => {
        let url = Config.API_URL + "api/source-item/" + id
        
        let body = {
        }
        let query = undefined
        return deleteCall(url, query, body);
    }

    return {
        exportValidationResults,
        postExportSourceItem,
        deleteSourceItem
    };
}