import React, { useState } from "react";
import { Grid } from '@mui/material';
import MappingList from "./MappingList";

export default function MappingSection({customDataModel}) {

    const sectionStyle = {
        width: "auto",
        height: "auto",
        margin: "0px 20px 20px 20px",
        padding: "10px 20px 10px 20px",
        gap: "10px"
    }

    return  <Grid item container xs={12} direction="column" style={sectionStyle}>
                <Grid item xs >
                    <MappingList customDataModel={customDataModel} />
                </Grid>
            </Grid>

}   