import { createSlice } from "@reduxjs/toolkit";

export const optionsSlice = createSlice({
    name: 'options',
    initialState: {
        useClassification: true,
        classificationValue: '',
        keyValue: '',

    },
    reducers: {
        setClassification: (state, action) => {
            state.useClassification = action;
        },
        setClassificationValue: (state, action) => {
            state.classificationValue = action;
        },
        setKeyValue: (state, action) => {
            state.keyValue = action;
        },
    }
});

export const { setClassification, setClassificationValue, setKeyValue } = optionsSlice.actions;

export default optionsSlice.reducer;