import React, { useEffect, useState } from "react";
import { Grid, NativeSelect, Switch, FormControl, FormLabel, RadioGroup, FormControlLabel, Divider, TextField, Button, Typography } from '@mui/material';

export default function MappingOptions({items, setName, setSelectedClassificationValue, setSelectedKeyValue}) {
    const [selectedClassification, setSelectedClassification] = useState(undefined);
    const [selectedKey, setSelectedKey] = useState(undefined);
    const [hasClassification, setHasClassification] = useState(false);

    const handleSelectedKeyChange = (key) => {
        setSelectedKey(key);
    }

    const handleSelectedClassificationChange = (key) => {
        setSelectedClassification(key);
    }

    const handleHasClassification = (value) => {
        setHasClassification(value);
        setSelectedClassificationValue(items[0].field);
    }

    useEffect(() => {
        if(items !== undefined && items.length > 0) {
            setSelectedKey(0);
        }
    },[items])

    useEffect(() => {
        if(selectedKey !== undefined) {
            if(items !== undefined) {
                setSelectedKeyValue(items[selectedKey].field);
            }
        }
    }, [selectedKey])

    useEffect(() => {
        if(selectedClassification !== undefined) {
            if(items !== undefined) {
                setSelectedClassificationValue(items[selectedClassification].field);
            }
        } else {
            setSelectedClassificationValue(undefined);
        }
    }, [selectedClassification])

    useEffect(() => {
        if(hasClassification !== undefined) {
            setSelectedClassification(undefined);
        }
    }, [hasClassification])
    
    let optionStyle = {
        marginLeft: "15px",
        marginRight: "15px"
    }    

    return  <Grid container direction="column" rowSpacing={2}>
                <Grid item container direction="row" xs={12} justifyContent={"center"}>
                    <Grid item xs={6}><Typography variant="addTitles">Has Classification:</Typography></Grid>
                    <Grid item xs={6}>
                        <Switch style={{color:"primary"}} value={hasClassification} onChange={(e) => handleHasClassification(e.target.checked)} />
                    </Grid>
                </Grid>
                <Grid item container direction="row" xs={12} justifyContent={"center"}>
                    <Grid item xs={6}><Typography variant="addTitles">Key Field:</Typography></Grid>
                    <Grid item xs={6}>
                        <NativeSelect style={optionStyle}
                            value={selectedKey}
                            onChange={(e) => handleSelectedKeyChange(e.target.value)}
                            label=""
                        >
                            {items.map((value, key) => (
                                <option key={key} value={key}>{value.field}</option>
                            )) }
                        </NativeSelect>
                    </Grid>
                </Grid>
                {hasClassification ?
                    <Grid item container direction="row" xs={12} justifyContent={"center"}>
                        <Grid item xs={6}><Typography variant="addTitles">Classification field:</Typography></Grid>
                        <Grid item xs={6}>
                            <NativeSelect style={optionStyle}
                                value={selectedClassification}
                                label=""
                                onChange={(e) => {handleSelectedClassificationChange(e.target.value)}}
                            >
                                {items.map((value, key) => (
                                    <option key={key} value={key}>{value.field}</option>
                                )) }
                            </NativeSelect>       
                        </Grid>
                    </Grid>
                :
                    ''
                }
                <Grid item container direction="row" xs={12} justifyContent={"center"}>
                    <Grid item xs={6}><Typography variant="addTitles">Target Model Name:</Typography></Grid>
                    <Grid item xs={6}><TextField size="small" placeholder="Target Model" fullWidth hiddenLabel variant="outlined" onChange={(e) => setName(e.target.value)}/></Grid>
                </Grid> 
            </Grid>
}   