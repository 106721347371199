import React, { useEffect } from "react";
import TMMList from "./TMMList";
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setSelectedIndex } from "../../features/selectedIndexSlice";

const NAME = "TargetModel";

export default function TargetModelsManager() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setSelectedIndex(NAME));
    }, []);

    return  <Box name={NAME} height="calc(100% - 75px)" width="100%" sx={{padding: "25px"}}>
                <TMMList />
            </Box>;
}