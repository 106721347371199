import React, { useState, useEffect } from "react";
import { Grid, Box, Button, TextField, Typography, InputAdornment } from '@mui/material';
import toolsMappingApi from "../../tools/toolsMappingApi";
import CircularProgress from '@mui/material/CircularProgress';

export default function OnneHelperBody({model, type, version}) {
    const { getDataModelEntry } = toolsMappingApi();
    const [dataModelEntry, setDataModelEntry] = useState(undefined);
    /*
        {
            "name": "propertyMeasurement",
            "longName": "Power rating",
            "fieldId": "4.052"
        }
    */

    useEffect(() => {
        if(model !== undefined){
            getDataModelEntry(type, version, model.fieldId).then((data) => {
                setDataModelEntry(data.payload);
            });
        }
    }, [model])

    /* useEffect(()=>{
        console.log(dataModelEntry)
    }, [dataModelEntry]) */

    return  dataModelEntry !== undefined ?
                <Grid item container direction="column" spacing={1} padding={5}>
                    <Grid item>
                        <Typography variant="helperTitle">
                            {model.longName}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="helperText">
                            {dataModelEntry.definition}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="helperSubtitle">
                            Instructions
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="helperText">
                            {dataModelEntry.instructions}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="helperSubtitle">
                            Example
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="helperText">
                            {dataModelEntry.example}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="helperSubtitle">
                            Dependencies
                        </Typography>
                    </Grid>
                    <Grid item>
                        {dataModelEntry.dependencies}
                    </Grid>
                </Grid>
            : 
                model !== undefined ?
                    <Grid item container direction="column" justifyItems={"center"} alignItems={"center"} height={"100%"}>
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                :
                    ''
}