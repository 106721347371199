import { createSlice } from "@reduxjs/toolkit";

export const openUpdateNotificationSlice = createSlice({
    name: 'openUpdateNotification',
    initialState: {
        value: {open: false, description: undefined, updateId: undefined }
    },
    reducers: {
        setOpenUpdateNotification: (state, action) => {
            state.value = action.payload;
        },
    }
});

export const { setOpenUpdateNotification } = openUpdateNotificationSlice.actions;

export default openUpdateNotificationSlice.reducer;