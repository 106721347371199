import { createSlice } from "@reduxjs/toolkit";

export const dmFieldsDataSlice = createSlice({
    name: 'dmFieldsData',
    initialState: {
        value: {
            TrascendingTotal: undefined,
            TrascendingComplete: undefined, 
            BrickTotal: undefined,
            BrickComplete: undefined,
        },
    },
    reducers: {
        setDmFieldsData: (state, action) => {
            state.value = action.payload;
        },
    }
});

export const { setDmFieldsData } = dmFieldsDataSlice.actions;

export default dmFieldsDataSlice.reducer;